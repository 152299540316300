<!--
 * @FileDescription:网盟-订单界面
 * @Author: 蔡林泽
 * @Date: 2022/3/9
 * @LastModifiedTime: 2022/3/9
 -->
<template>
  <div class="main">
    <h1>Orders</h1>
    <div class="data f">
      <div>
        <p>Order Value</p>
        <span>US$ {{ Liststats.order_amount }}</span>
      </div>
      <div>
        <p>Commision</p>
        <span>US$ {{ Liststats.commision }}</span>
      </div>
    </div>
    <div class="list-filter bg-white f">
      <!-- 搜索 -->
      <el-input v-model="input" clearable placeholder="Please enter the content" class="input-with-select" style="width:400px;">
        <el-select slot="prepend" v-model="select" style="width:150px;">
          <el-option label="Order ID" value="order_no" />
          <el-option label="Customer Name" value="customer_name" />
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="resetPage" />
      </el-input>

      <el-select v-model="timeSelect" placeholder="Please select" style="width:150px;margin-right:0px;" @change="pickerChange">
        <el-option label="Created Date" value="1" />
        <el-option label="Updated Date" value="2" />
      </el-select>
      <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="Start date" end-placeholder="End date" @change="pickerChange" />
    </div>
    <el-tabs v-model="activeName" style="background: #fff;" @tab-click="handleClick">
      <el-tab-pane label="Commission Received" name="1">
        <TableContainer :current="listForm.page" :total="total" :height="($store.state.common.clientHeight-394)+'px'" @change="pageChange">
          <el-table ref="table" v-loading="loading" :data="list" :height="($store.state.common.clientHeight-451)+'px'">
            <!-- 嵌套表格 -->
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.OrderProduct" :show-header="false" class="tableStyle">
                  <el-table-column label="Order ID" width="225">
                    <template slot-scope="scope">
                      <info :img="scope.row.product_image" :name="scope.row.product_name" :code="scope.row.product_qty" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Customer Name" width="200" />
                  <el-table-column label="Order Value(USD)" width="200">
                    <template slot-scope="scope">
                      <p>{{ +scope.row.product_qty * +scope.row.product_amount }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Commision Rate" width="200">
                    <template slot-scope="scope">
                      <p>{{ scope.row.commision_rate +'%' }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="commision" label="Commision(USD)" width="200" />
                  <el-table-column label="Order Status" width="200">
                    <template slot-scope="scope">
                      <p>{{ +scope.row.product_status===1?'Shipped':+scope.row.product_status===2? 'Completed':+scope.row.product_status===3?'Refunded':'' }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="created_at" label="Created Date" min-width="200" />
                  <el-table-column prop="updated_at" label="Updated Date" min-width="200" />
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="order_no" label="Order ID" width="200" />
            <el-table-column prop="customer_name" label="Customer Name" width="200" />
            <el-table-column prop="order_amount" label="Order Value(USD)" width="200" />
            <el-table-column label="Commision Rate" width="200">
              <template slot-scope="scope">
                <p>{{ scope.row.commision_rate +'%' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="commision" label="Commision(USD)" width="200" />
            <el-table-column label="Order Status" width="200">
              <template slot-scope="scope">
                <p>{{ +scope.row.order_status===1?'Shipped':+scope.row.order_status===2? 'Completed':+scope.row.order_status===3?'Refunded':'' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="Created Date" min-width="200" />
            <el-table-column prop="updated_at" label="Updated Date" min-width="200" />
          </el-table>
        </TableContainer>
      </el-tab-pane>
      <el-tab-pane label="Commission Unreceived" name="2">
        <TableContainer :current="listForm.page" :total="total" :height="($store.state.common.clientHeight-394)+'px'" @change="pageChange">
          <el-table ref="table" v-loading="loading" :data="list" :height="($store.state.common.clientHeight-451)+'px'">
            <!-- 嵌套表格 -->
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.OrderProduct" :show-header="false" class="tableStyle">
                  <el-table-column label="Order ID" width="225">
                    <template slot-scope="scope">
                      <info :img="scope.row.product_image" :name="scope.row.product_name" :code="scope.row.product_qty" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Customer Name" width="200" />
                  <el-table-column label="Order Value(USD)" width="200">
                    <template slot-scope="scope">
                      <p>{{ +scope.row.product_qty * +scope.row.product_amount }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column label="Commision Rate" width="200">
                    <template slot-scope="scope">
                      <p>{{ scope.row.commision_rate +'%' }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="commision" label="Commision(USD)" width="200" />
                  <el-table-column label="Order Status" width="200">
                    <template slot-scope="scope">
                      <p>{{ +scope.row.product_status===1?'Shipped':+scope.row.product_status===2? 'Completed':+scope.row.product_status===3?'Refunded':'' }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="created_at" label="Created Date" min-width="200" />
                  <el-table-column prop="updated_at" label="Updated Date" min-width="200" />
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="order_no" label="Order ID" width="200" />
            <el-table-column prop="customer_name" label="Customer Name" width="200" />
            <el-table-column prop="order_amount" label="Order Value(USD)" width="200" />
            <el-table-column label="Commision Rate" width="200">
              <template slot-scope="scope">
                <p>{{ scope.row.commision_rate +'%' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="commision" label="Commision(USD)" width="200" />
            <el-table-column prop="order_status" label="Order Status" width="200">
              <template slot-scope="scope">
                <p>{{ +scope.row.order_status===1?'Shipped':+scope.row.order_status===2? 'Completed':+scope.row.order_status===3?'Refunded':'' }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="Created Date" min-width="200" />
            <el-table-column prop="updated_at" label="Updated Date" min-width="200" />
          </el-table>
        </TableContainer>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import API from '@/api/index'
import list from '@/mixin/list'

export default {
  mixins: [list],
  data() {
    return {
      tableData: [],
      loading: false,
      select: 'order_no',
      input: '',
      time: '',
      timeSelect: '1',
      Liststats: '',
      activeName: '1'
    }
  },
  created() {
    this.listForm.order_status = ['2']
    this.getList()
    this.getListstats()
  },
  methods: {
    handleClick(tab, event) {
      if (this.activeName === '1') {
        this.listForm.order_status = ['2']
      } else {
        this.listForm.order_status = ['1', '3']
      }
      this.getList()
    },
    getListstats() {
      API.getListstats().then(res => {
        this.Liststats = res.data
      })
    },
    pickerChange() {
      if (!this.time) {
        this.listForm.order_updated_at_start = undefined
        this.listForm.order_updated_at_end = undefined
        this.listForm.order_created_at_start = undefined
        this.listForm.order_created_at_end = undefined
      } else
      if (this.timeSelect === '1' && this.time) {
        this.listForm.order_created_at_start = this.time[0]
        this.listForm.order_created_at_end = this.time[1]
        this.listForm.order_updated_at_start = undefined
        this.listForm.order_updated_at_end = undefined
      } else if (this.timeSelect === '2' && this.time) {
        this.listForm.order_updated_at_start = this.time[0]
        this.listForm.order_updated_at_end = this.time[1]
        this.listForm.order_created_at_start = undefined
        this.listForm.order_created_at_end = undefined
      }
      this.getList()
    },
    getList() {
      let params = Object.assign({}, this.listForm, { [this.select]: this.input || undefined })
      this.loading = true
      API.getOrdersList(params).then(res => {
        this.list = res.data.list || []
        this.total = res.data.pages.total
      }).finally(() => {
        this.loading = false
      })
    },
    Jump(row) {
      if (row.state === 'End') return this.$message.warning('The current share link is invalid')
      window.open(row.url)
    },
    handleEdit(row) {
      this.$refs.details.showDialog(row)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.main {
  .data {
    width: 100%;
    height: 120px;
    margin: 10px 0;
    background: #ffffff;
    div {
      // margin: 40px;
      padding-top: 20px;
      margin: 0 110px;
      p {
        line-height: 43px;
        font-weight: 500;
        font-size: 16px;
        color: #747a87;
      }
      span {
        font-weight: 500;
        font-size: 26px;
        color: #000000;
      }
    }
  }
}
.tableStyle {
  /deep/.el-table__body-wrapper {
    overflow-x: hidden;
  }
}
/deep/ .el-tabs__nav {
  margin-left: 20px;
}
</style>
